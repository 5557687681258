import './style.scss';

const articleList = [
  {title: 'Facilisis massa netus luctus interdum iaculis', desc: 'Tincidunt posuere convallis nisi, orci fringilla volutpat nisl nunc est. Facilisis massa netus luctus interdum iaculis maecenas velit amet diam.'},
  {title: 'Facilisis massa netus luctus interdum iaculis', desc: 'Tincidunt posuere convallis nisi, orci fringilla volutpat nisl nunc est. Facilisis massa netus luctus interdum iaculis maecenas velit amet diam.'},
  {title: 'Facilisis massa netus luctus interdum iaculis', desc: 'Tincidunt posuere convallis nisi, orci fringilla volutpat nisl nunc est. Facilisis massa netus luctus interdum iaculis maecenas velit amet diam.'}
]

const WritingPage = () => (
  <section className="writing-page">
    {articleList.map((article, index) => (
      <div className="article__wrapper" key={index}>
        <div className={`article ${(index + 1) % 2 === 0 && "article_reverse"}`}>
          <div className="article__number-block">
            <p className="article__number">
              {index < 9 ? `0${index + 1}`: index + 1}
              <span className="article__number-text">Article {index + 1}</span>
            </p>
          </div>
          <div className="article__delimiter-wrapper">
            <hr className="article__delimiter" />
          </div>
          <div className="article__info-block-wrapper">
            <div className="article__info-block">
              <p className="article__title">{article.title}</p>
              <p className="article__description">{article.desc}</p>
            </div>
          </div>
        </div>
      </div>
    ))}
  </section>
);

export default WritingPage;
