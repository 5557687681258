import './style.scss';

// import {ReactComponent as InstagramIcon} from "assets/icons/social/instagram.svg";
import {ReactComponent as LinkedinIcon} from "assets/icons/social/linkedin.svg";
//import {ReactComponent as TwitterIcon} from "assets/icons/social/twitter.svg";
// import {ReactComponent as FacebookIcon} from "assets/icons/social/facebook.svg";
//import {ReactComponent as OpenseaIcon} from "assets/icons/social/opensea.svg";
// import {ReactComponent as FlagIcon} from "assets/icons/social/flag.svg";
//import {ReactComponent as DollarIcon} from "assets/icons/social/dollar.svg";

const ContactPage = () => (
  <section className="contact-page">
    <div className="contact-block">
      <p className="contact-block__title">Email</p>
      <a href="mailto:contact@damonnam.com" className="contact-block__text">contact@damonnam.com</a>
    </div>
    <div className="contact-block contact-block_follow">
      <p className="contact-block__title">Follow</p>
      <div className="contact-block__social-row">
        {/*<a className="contact-block__social-icon-wrapper" href="https://instagram.com/damon" target="_blank" rel="noreferrer">*/}
        {/*  <InstagramIcon className="contact-block__social-icon" />*/}
        {/*</a>*/}
        <a className="contact-block__social-icon-wrapper" href="https://linkedin.com/in/damonnam" target="_blank" rel="noreferrer">
          <LinkedinIcon className="contact-block__social-icon" />
        </a>
        {/*<a className="contact-block__social-icon-wrapper" href="https://twitter.com/damonnam" target="_blank" rel="noreferrer">*/}
        {/*  <TwitterIcon className="contact-block__social-icon" />*/}
        {/*</a>*/}
        {/*<div className="contact-block__social-icon-wrapper">*/}
        {/*  <FacebookIcon className="contact-block__social-icon" />*/}
        {/*</div>*/}
        {/*<a className="contact-block__social-icon-wrapper" href="https://opensea.io/collection/privesociete" target="_blank" rel="noreferrer">*/}
        {/*  <OpenseaIcon className="contact-block__social-icon" />*/}
        {/*</a>*/}
        {/*<div className="contact-block__social-icon-wrapper">*/}
        {/*  <FlagIcon className="contact-block__social-icon" />*/}
        {/*</div>*/}
        {/*<a className="contact-block__social-icon-wrapper" href="http://cash.app/damonnam" target="_blank" rel="noreferrer">*/}
        {/*  <DollarIcon className="contact-block__social-icon" />*/}
        {/*</a>*/}
      </div>
    </div>
  </section>
);

export default ContactPage;
