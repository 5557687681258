import {ReactNode} from "react";

import {Navigation} from "components";

import './style.scss';
import useScreenSize from "hooks/useScreenSize";


interface BaseLayoutProps {
  children: ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({children}) => {
  const { isMobile } = useScreenSize();

  return (
    <div className="base-layout">
      <div className="base-layout__menu-wrapper">
        <Navigation pageView={children}/>
      </div>
      {!isMobile &&
        <div className="base-layout__content-wrapper">
          {children}
        </div>
      }
    </div>
  )
};

export default BaseLayout;
