import './style.scss';

import PriveIconUrl from "assets/images/Prive.webp"
import CoinIconUrl from "assets/images/Coin.webp"
import PriveReserveIconUrl from "assets/images/PriveReserve.webp"
import HodlverseIconUrl from "assets/images/Hodlverse.webp"

const CompaniesPage = () => (
  <section className="companies">
    <div className="company__wrapper">
      <a className="company__image-wrapper" href="https://coindefi.org" target="_blank" rel="noreferrer">
        <img srcSet={`${CoinIconUrl} 2x`} alt="Coin" className="company__image" />
      </a>
    </div>
    <div className="company__wrapper">
      <a className="company__image-wrapper company__image-wrapper_prive" href="https://www.prive.io" target="_blank" rel="noreferrer">
        <img srcSet={`${PriveIconUrl} 2x`} alt="Prive" className="company__image" />
      </a>
    </div>
    <div className="company__wrapper">
      <a className="company__image-wrapper company__image-wrapper_prive-reserve" href="https://privereserve.com" target="_blank" rel="noreferrer">
        <img srcSet={`${PriveReserveIconUrl} 2x`} alt="PriveReserve" className="company__image" />
      </a>
    </div>
    <div className="company__wrapper">
      <a className="company__image-wrapper company__image-wrapper_hodl" href="http://hodlverse.com/" target="_blank" rel="noreferrer">
        <img srcSet={`${HodlverseIconUrl} 2x`} alt="Hodlverse" className="company__image" />
      </a>
    </div>
  </section>
);

export default CompaniesPage;
