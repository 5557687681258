import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import {BaseLayout} from "./layouts"
import {HomePage, BioPage, ExperiencePage, CompaniesPage, ArtPage, WritingPage, ContactPage} from "./Pages"

function App() {
  return (
    <BrowserRouter>
      <BaseLayout>
        <Routes>
          <Route path="/" key="home" element={<HomePage />} />
          <Route path="/bio" key="bio" element={<BioPage />} />
          <Route path="/experience" key="experience" element={<ExperiencePage />} />
          <Route path="/companies" key="companies" element={<CompaniesPage />} />
          <Route path="/art" key="art" element={<ArtPage />} />
          <Route path="/writing" key="writing" element={<WritingPage />} />
          <Route path="/contact" key="contact" element={<ContactPage />} />
          <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
      </BaseLayout>
    </BrowserRouter>
  );
}

export default App;
