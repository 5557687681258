import { useEffect, useState} from "react";

import config from "config";

const getScreenWidth = () => {
	return window.innerWidth
		|| document.documentElement.clientWidth
		|| document.body.clientWidth;
}

// export const getScreenHeight = () => {
// 	return window.innerHeight
// 		|| document.documentElement.clientHeight
// 		|| document.body.clientHeight;
// }

const useScreenSize = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const onResize = () => {
			const width = getScreenWidth();
			setIsMobile(width <= config.sizes.mobile)
		}

		window.addEventListener('resize', onResize)
		onResize();

		return () => {
			window.removeEventListener('resize', onResize)
		}
	}, [])

	return { isMobile };
};

export default useScreenSize;
