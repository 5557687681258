import {ReactNode, useEffect} from "react";
import './style.scss';

import {Link, useLocation} from "react-router-dom";
import useScreenSize from "hooks/useScreenSize";

const links = [
  {title: 'Home', link: '/'},
  {title: 'Bio', link: '/bio'},
  {title: 'Experience', link: '/experience'},
  // {title: 'Companies', link: '/companies'},
  {title: 'Art', link: '/art'},
  // {title: 'Writing', link: '/writing'},
  {title: 'Contact', link: '/contact'},
]

interface NavigationProps {
  pageView: ReactNode;
}

const Navigation: React.FC<NavigationProps> = ({pageView}) => {
  const { isMobile } = useScreenSize();

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (isMobile) {
      const activeElements = document.getElementsByClassName("base-layout__content-wrapper_active");
      if (activeElements?.length) {
        window.scrollTo(0, (activeElements[0] as HTMLElement).offsetTop - 100)
      }
    }
  }, [isMobile, pathname])

  return (
    <div className="navigation">
      <div className="navigation__links-wrapper">
        <div className="menu-block__wrapper">
          <div className="menu-block">
            <p className="menu-block__text">Menu</p>
            <hr className="menu-block__delimiter" />
          </div>
        </div>
        <div className="navigation__link-menu-wrapper">
          {links.map((link, index) => (
            <section key={index} className="navigation__link-section">
              <div className="navigation__link-wrapper">
                <Link to={link.link}
                      className={`navigation__link ${pathname === link.link && "navigation__link_active"}`}>
                  {link.title}
                </Link>
              </div>
              {isMobile &&
              <div className={`base-layout__content-wrapper ${pathname === link.link ? "base-layout__content-wrapper_active" : "base-layout__content-wrapper_hidden"}`}>
                {pageView}
              </div>
              }
            </section>
          ))}
        </div>
      </div>
    </div>
  )
};

export default Navigation;
