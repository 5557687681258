import './style.scss';

const BioPage = () => (
  <section className="bio">
    <div className="bio__title-wrapper">
      <p className="bio__title">Bio</p>
      <p className="bio__subtitle">Damon Nam</p>
    </div>
    <article className="bio__article">
      <div className="bio__text-block">
        <p className="bio__text">
          Damon Nam is a twenty-three plus year technology executive, Microsoft alumnus, and entrepreneur. His passions as a creator include art, digital goods, and physical products as seen in his ventures in the blockchain, spirits, and fashion industries. His companies have collectively generated millions in revenue, financial transactions, and impressions across multiple sectors. Damon also has extensive experience in fundraising and corporate strategy from advising other companies and as an operator himself.
        </p>
      </div>
      <div className="bio__text-block">
        <p className="bio__text">
          Prior to starting his personal corporate ventures, Damon enjoyed a decorated seventeen-year career at Microsoft and assumed a number of different business management roles within the Microsoft Services organization. In his last role at Microsoft as a US Services Program Director, he was responsible for driving and managing the Preferred Services Partner program for the US subsidiary; a program with approximately seventy-five partner organizations and a total book of business of over $90 million in revenue.
        </p>
      </div>
      <div className="bio__text-block">
        <p className="bio__text">
          Damon studied Information Technology and has a Bachelor of Science in Business at Southern Methodist University. He has also been accepted into Entrepreneur Magazine’s Leadership Network and is a contributor and guest writer.
        </p>
      </div>
    </article>
  </section>
);

export default BioPage;
