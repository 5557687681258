import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Keyboard, Autoplay} from 'swiper';

import Damon1IconUrl from "assets/images/Damon1.webp"
import Damon2IconUrl from "assets/images/Damon2.webp"
import Damon3IconUrl from "assets/images/Damon3.webp"
import Damon4IconUrl from "assets/images/Damon4.webp"
// import Damon5IconUrl from "assets/images/Damon5.webp"
import Damon6IconUrl from "assets/images/Damon6.webp"

import './style.scss';
import 'swiper/css';
import 'swiper/css/pagination';

const sliderList = [
  Damon1IconUrl,
  Damon2IconUrl,
  Damon3IconUrl,
  Damon4IconUrl,
  // Damon5IconUrl,
  Damon6IconUrl
]

const HomePage = () => (
  <section className="home-page">
    <Swiper
      modules={[Pagination, Keyboard, Autoplay]}
      centeredSlides={true}
      spaceBetween={0}
      keyboard={{ enabled: true }}
      slidesPerView={1}
      loop
      pagination={{ clickable: true }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    >
      {sliderList.map((imgUrl, index) => (
        <SwiperSlide key={index}>
          <div className="home-page__slide">
            <img className="home-page__slide-img" srcSet={`${imgUrl} 2x`} alt="Slider"/>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </section>
);

export default HomePage;
