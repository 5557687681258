import './style.scss';

const ExperiencePage = () => (
  <section className="experience">
    <div className="experience__row">
      <div className="experience__section">
        <p className="experience__title">Experience</p>

        <div className="experience__block">
          <p className="experience__block-title">Investing</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option">Personal Finance</li>
            <li className="experience__block-option">Venture Capital and Startups</li>
            <li className="experience__block-option">Crowdfunding</li>
          </ul>
        </div>

        <div className="experience__block">
          <p className="experience__block-title">Corporate Formations</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option">LLC</li>
            <li className="experience__block-option">C Corp</li>
            <li className="experience__block-option">501(c3)</li>
          </ul>
        </div>

        <div className="experience__block">
          <p className="experience__block-title">Securities Offerings</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option">Reg D</li>
            <li className="experience__block-option">Reg S</li>
            <li className="experience__block-option">Reg CF</li>
          </ul>
        </div>
        <div className="experience__block">
          <p className="experience__block-title">Token Issuance</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option">Initial Coin Offering</li>
            <li className="experience__block-option">Security Token Offerings</li>
            <li className="experience__block-option">NFT Mint</li>
          </ul>
        </div>
        <div className="experience__block">
          <p className="experience__block-title">Business</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option">Corporate Advisory and Strategy</li>
            <li className="experience__block-option">Business Development and Partnerships</li>
            <li className="experience__block-option">Marketing</li>
            <li className="experience__block-option">User Experience and Design</li>

          </ul>
        </div>
      </div>
      <div className="experience__section">
        <p className="experience__title">Career</p>
        <div className="experience__block">
          <p className="experience__block-title">Orange</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option experience__block-option_no-circle">Founder and CEO:&nbsp;&nbsp;April 2023 to Present</li>
          </ul>
        </div>
        <div className="experience__block">
          <p className="experience__block-title">Coin</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option experience__block-option_no-circle">Founder and CEO:&nbsp;&nbsp;August 2017 to March 2023</li>
          </ul>
        </div>
        <div className="experience__block">
          <p className="experience__block-title">PRIVÉ Capital</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option experience__block-option_no-circle">Founder and CEO:&nbsp;&nbsp;January 2015 to Present</li>
          </ul>
        </div>
        <div className="experience__block">
          <p className="experience__block-title">Microsoft Corporation</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option">US Partner Services Director:&nbsp;&nbsp;February 2008 to January 2015</li>
            <li className="experience__block-option">Resource Manager:&nbsp;&nbsp;March 2006 to January 2008</li>
            <li className="experience__block-option">Technical Account Manager:&nbsp;&nbsp;July 1999 to February 2006</li>
            <li className="experience__block-option">University Consultant:&nbsp;&nbsp;July 1998 to July 1999</li>
          </ul>
        </div>
        <div className="experience__block">
          <p className="experience__block-title">Contributing Author</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option">Entrepreneur Magazine</li>
            <li className="experience__block-option">Nasdaq</li>
          </ul>
        </div>
      </div>
    </div>
    <div className="experience__row">
      <div className="experience__section">
        <p className="experience__title">Education</p>
        <div className="experience__block">
          <p className="experience__block-title">Indiana University – Kelley School of Business</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option experience__block-option_no-circle">Microsoft Business Management Certificate Program</li>
          </ul>
        </div>
        <div className="experience__block">
          <p className="experience__block-title">Southern Methodist University – Cox School of Business</p>
          <ul className="experience__block-option-list">
            <li className="experience__block-option experience__block-option_no-circle">Bachelors of Business Administration degree in Management Information Systems</li>
          </ul>
        </div>
      </div>
    </div>
    <div className="experience__row">
      <div className="experience__section">
        <p className="experience__title experience__title_certificate">Certifications</p>
        <div className="experience__block">
          <ul className="experience__block-option-list">
            <li className="experience__block-option">Certified Bitcoin Professional</li>
            <li className="experience__block-option">Microsoft Windows 2003 Certification</li>
            <li className="experience__block-option">Microsoft Certified Systems Administrator</li>
            <li className="experience__block-option">Project Management Basics for Team Leads</li>
            <li className="experience__block-option">FKA Instructional Techniques</li>
            <li className="experience__block-option">Microsoft Windows 2000 Certification</li>
            <li className="experience__block-option">Information Technology Infrastructure Library Foundation</li>
            <li className="experience__block-option">Microsoft Windows NT4 Certification</li>
            <li className="experience__block-option">Microsoft Certified Systems Engineer</li>
            <li className="experience__block-option">Microsoft Certified Professional</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

export default ExperiencePage;
