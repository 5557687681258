const config = {
  sizes: {
    mobile: 767
  },
  GLOBAL: {
    DOMAIN_URL: process.env.REACT_APP_DOMAIN_URL
  }
}

export default config;
